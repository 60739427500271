import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import './App.scss';
import { NotFound } from './NotFound';
import { FantasyShoujo } from './FantasyShoujo';
import { Home } from './Home';

function App() {
    return <>
        <Router>
            <Routes>
                <Route index element={<Home/>}/>
                <Route path="/fantasy-shoujo" element={<FantasyShoujo/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </Router>
    </>;
}

export default App;
