const FantansyShoujoAbi = [
    {
        'inputs': [{'internalType': 'address', 'name': 'hot_wallet', 'type': 'address'}, {
            'internalType': 'string',
            'name': 'base_uri',
            'type': 'string'
        }], 'stateMutability': 'nonpayable', 'type': 'constructor'
    }, {'inputs': [], 'name': 'ApprovalCallerNotOwnerNorApproved', 'type': 'error'}, {
        'inputs': [],
        'name': 'ApprovalQueryForNonexistentToken',
        'type': 'error'
    }, {'inputs': [], 'name': 'BalanceQueryForZeroAddress', 'type': 'error'}, {
        'inputs': [],
        'name': 'MintERC2309QuantityExceedsLimit',
        'type': 'error'
    }, {'inputs': [], 'name': 'MintToZeroAddress', 'type': 'error'}, {
        'inputs': [],
        'name': 'MintZeroQuantity',
        'type': 'error'
    }, {'inputs': [], 'name': 'OwnerQueryForNonexistentToken', 'type': 'error'}, {
        'inputs': [],
        'name': 'OwnershipNotInitializedForExtraData',
        'type': 'error'
    }, {'inputs': [], 'name': 'TransferCallerNotOwnerNorApproved', 'type': 'error'}, {
        'inputs': [],
        'name': 'TransferFromIncorrectOwner',
        'type': 'error'
    }, {'inputs': [], 'name': 'TransferToNonERC721ReceiverImplementer', 'type': 'error'}, {
        'inputs': [],
        'name': 'TransferToZeroAddress',
        'type': 'error'
    }, {'inputs': [], 'name': 'URIQueryForNonexistentToken', 'type': 'error'}, {
        'anonymous': false,
        'inputs': [{
            'indexed': true,
            'internalType': 'address',
            'name': 'owner',
            'type': 'address'
        }, {'indexed': true, 'internalType': 'address', 'name': 'approved', 'type': 'address'}, {
            'indexed': true,
            'internalType': 'uint256',
            'name': 'tokenId',
            'type': 'uint256'
        }],
        'name': 'Approval',
        'type': 'event'
    }, {
        'anonymous': false,
        'inputs': [{
            'indexed': true,
            'internalType': 'address',
            'name': 'owner',
            'type': 'address'
        }, {'indexed': true, 'internalType': 'address', 'name': 'operator', 'type': 'address'}, {
            'indexed': false,
            'internalType': 'bool',
            'name': 'approved',
            'type': 'bool'
        }],
        'name': 'ApprovalForAll',
        'type': 'event'
    }, {
        'anonymous': false,
        'inputs': [{
            'indexed': true,
            'internalType': 'uint256',
            'name': 'fromTokenId',
            'type': 'uint256'
        }, {'indexed': false, 'internalType': 'uint256', 'name': 'toTokenId', 'type': 'uint256'}, {
            'indexed': true,
            'internalType': 'address',
            'name': 'from',
            'type': 'address'
        }, {'indexed': true, 'internalType': 'address', 'name': 'to', 'type': 'address'}],
        'name': 'ConsecutiveTransfer',
        'type': 'event'
    }, {
        'anonymous': false,
        'inputs': [{
            'indexed': true,
            'internalType': 'address',
            'name': 'previousOwner',
            'type': 'address'
        }, {'indexed': true, 'internalType': 'address', 'name': 'newOwner', 'type': 'address'}],
        'name': 'OwnershipTransferred',
        'type': 'event'
    }, {
        'anonymous': false,
        'inputs': [{
            'indexed': true,
            'internalType': 'address',
            'name': 'from',
            'type': 'address'
        }, {'indexed': true, 'internalType': 'address', 'name': 'to', 'type': 'address'}, {
            'indexed': true,
            'internalType': 'uint256',
            'name': 'tokenId',
            'type': 'uint256'
        }],
        'name': 'Transfer',
        'type': 'event'
    }, {
        'inputs': [{'internalType': 'address', 'name': 'to', 'type': 'address'}, {
            'internalType': 'uint256',
            'name': 'tokenId',
            'type': 'uint256'
        }], 'name': 'approve', 'outputs': [], 'stateMutability': 'payable', 'type': 'function'
    }, {
        'inputs': [{'internalType': 'address', 'name': 'owner', 'type': 'address'}],
        'name': 'balanceOf',
        'outputs': [{'internalType': 'uint256', 'name': '', 'type': 'uint256'}],
        'stateMutability': 'view',
        'type': 'function'
    }, {
        'inputs': [{'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256'}],
        'name': 'getApproved',
        'outputs': [{'internalType': 'address', 'name': '', 'type': 'address'}],
        'stateMutability': 'view',
        'type': 'function'
    }, {
        'inputs': [],
        'name': 'getMintPrice',
        'outputs': [{'internalType': 'uint256', 'name': '', 'type': 'uint256'}],
        'stateMutability': 'view',
        'type': 'function'
    }, {
        'inputs': [],
        'name': 'getMintedCount',
        'outputs': [{'internalType': 'uint256', 'name': '', 'type': 'uint256'}],
        'stateMutability': 'view',
        'type': 'function'
    }, {
        'inputs': [],
        'name': 'getTotalOnChainSupply',
        'outputs': [{'internalType': 'uint256', 'name': '', 'type': 'uint256'}],
        'stateMutability': 'view',
        'type': 'function'
    }, {
        'inputs': [{'internalType': 'address', 'name': 'owner', 'type': 'address'}, {
            'internalType': 'address',
            'name': 'operator',
            'type': 'address'
        }],
        'name': 'isApprovedForAll',
        'outputs': [{'internalType': 'bool', 'name': '', 'type': 'bool'}],
        'stateMutability': 'view',
        'type': 'function'
    }, {
        'inputs': [{'internalType': 'address', 'name': 'wallet', 'type': 'address'}],
        'name': 'isBlacklisted',
        'outputs': [{'internalType': 'bool', 'name': '', 'type': 'bool'}],
        'stateMutability': 'view',
        'type': 'function'
    }, {
        'inputs': [],
        'name': 'isMintable',
        'outputs': [{'internalType': 'uint8', 'name': '', 'type': 'uint8'}],
        'stateMutability': 'view',
        'type': 'function'
    }, {
        'inputs': [],
        'name': 'mint',
        'outputs': [],
        'stateMutability': 'payable',
        'type': 'function'
    }, {
        'inputs': [],
        'name': 'name',
        'outputs': [{'internalType': 'string', 'name': '', 'type': 'string'}],
        'stateMutability': 'view',
        'type': 'function'
    }, {
        'inputs': [{'internalType': 'uint256', 'name': 'limit', 'type': 'uint256'}],
        'name': 'offchainMint',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    }, {
        'inputs': [],
        'name': 'owner',
        'outputs': [{'internalType': 'address', 'name': '', 'type': 'address'}],
        'stateMutability': 'view',
        'type': 'function'
    }, {
        'inputs': [{'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256'}],
        'name': 'ownerOf',
        'outputs': [{'internalType': 'address', 'name': '', 'type': 'address'}],
        'stateMutability': 'view',
        'type': 'function'
    }, {
        'inputs': [],
        'name': 'renounceOwnership',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    }, {
        'inputs': [{'internalType': 'address', 'name': 'from', 'type': 'address'}, {
            'internalType': 'address',
            'name': 'to',
            'type': 'address'
        }, {'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256'}],
        'name': 'safeTransferFrom',
        'outputs': [],
        'stateMutability': 'payable',
        'type': 'function'
    }, {
        'inputs': [{'internalType': 'address', 'name': 'from', 'type': 'address'}, {
            'internalType': 'address',
            'name': 'to',
            'type': 'address'
        }, {'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256'}, {
            'internalType': 'bytes',
            'name': '_data',
            'type': 'bytes'
        }], 'name': 'safeTransferFrom', 'outputs': [], 'stateMutability': 'payable', 'type': 'function'
    }, {
        'inputs': [{'internalType': 'address', 'name': 'operator', 'type': 'address'}, {
            'internalType': 'bool',
            'name': 'approved',
            'type': 'bool'
        }], 'name': 'setApprovalForAll', 'outputs': [], 'stateMutability': 'nonpayable', 'type': 'function'
    }, {
        'inputs': [{'internalType': 'string', 'name': 'uri', 'type': 'string'}],
        'name': 'setBaseUri',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    }, {
        'inputs': [{'internalType': 'address', 'name': 'wallet', 'type': 'address'}, {
            'internalType': 'bool',
            'name': 'status',
            'type': 'bool'
        }], 'name': 'setBlacklist', 'outputs': [], 'stateMutability': 'nonpayable', 'type': 'function'
    }, {
        'inputs': [{'internalType': 'bool', 'name': 'enabled', 'type': 'bool'}],
        'name': 'setMintStatus',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    }, {
        'inputs': [{'internalType': 'uint256', 'name': 'price', 'type': 'uint256'}],
        'name': 'setPrice',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    }, {
        'inputs': [{'internalType': 'uint256', 'name': 'quota', 'type': 'uint256'}],
        'name': 'setQuota',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    }, {
        'inputs': [{'internalType': 'bytes4', 'name': 'interfaceId', 'type': 'bytes4'}],
        'name': 'supportsInterface',
        'outputs': [{'internalType': 'bool', 'name': '', 'type': 'bool'}],
        'stateMutability': 'view',
        'type': 'function'
    }, {
        'inputs': [],
        'name': 'symbol',
        'outputs': [{'internalType': 'string', 'name': '', 'type': 'string'}],
        'stateMutability': 'view',
        'type': 'function'
    }, {
        'inputs': [{'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256'}],
        'name': 'tokenURI',
        'outputs': [{'internalType': 'string', 'name': '', 'type': 'string'}],
        'stateMutability': 'view',
        'type': 'function'
    }, {
        'inputs': [],
        'name': 'totalSupply',
        'outputs': [{'internalType': 'uint256', 'name': '', 'type': 'uint256'}],
        'stateMutability': 'view',
        'type': 'function'
    }, {
        'inputs': [{'internalType': 'address', 'name': 'from', 'type': 'address'}, {
            'internalType': 'address',
            'name': 'to',
            'type': 'address'
        }, {'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256'}],
        'name': 'transferFrom',
        'outputs': [],
        'stateMutability': 'payable',
        'type': 'function'
    }, {
        'inputs': [{'internalType': 'address', 'name': 'newOwner', 'type': 'address'}],
        'name': 'transferOwnership',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    }
];

export {FantansyShoujoAbi};
