import styles from './FantasyShoujo.module.scss';
import { useMetaMask } from 'metamask-react';
import Swal from 'sweetalert2';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { FantansyShoujoAbi } from './ABIs/FantansyShoujo';

export function FantasyShoujo() {
    // const rpc_url = 'https://cloudflare-eth.com/';
    const rpc_url = 'https://arb1.arbitrum.io/rpc';
    const contract_address = '0x72Bac8B606FabAc4a82cFC73348483e43Ffe5254';
    // const chain_id = '0x1';
    const chain_id = '0xA4B1';  // Arbitrum One

    const {status, connect, account, chainId, ethereum, switchChain} = useMetaMask();
    let [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);
    let [dedicatedProvider, setDedicatedProvider] = useState<ethers.providers.JsonRpcProvider | null>(null);
    let [contract, setContract] = useState<ethers.Contract | null>(null);
    let [contractRO, setContractRO] = useState<ethers.Contract | null>(null);
    const [price, setPrice] = useState<ethers.BigNumber | null>(null);
    const [mintable, setMintable] = useState<number>(-1);
    const [mintedCount, setMintedCount] = useState<number>(-1);
    const [supply, setSupply] = useState<number>(-1);
    const [reloadData, setReloadData] = useState<number>(0);

    useEffect(() => {
        if (contractRO) {
            console.log('ro', 'reloadData:', reloadData, 'chainId:', chainId)
            contractRO.getMintPrice().then(setPrice);
            Promise.all([contractRO.getMintedCount(), contractRO.getTotalOnChainSupply()]).then(([mintedCount, supply]) => {
                setMintedCount(mintedCount.toNumber());
                setSupply(supply.toNumber());
            });
        }
    }, [contractRO, reloadData, chainId])

    useEffect(() => {
        if (contract) {
            console.log('rw', 'reloadData:', reloadData, 'chainId:', chainId)
            contract.isMintable().then((r: number) => {
                console.log('updated mintable', r);
                setMintable(r);
            });
        }
    }, [contract, reloadData, chainId]);

    useEffect(() => {
        console.log('Status changed:', status);
        if (status === 'connected') {
            console.log('Connected, reloading data');
            setReloadData(reloadData + 1);
        }
    }, [status])

    if (!provider && ethereum) {
        provider = new ethers.providers.Web3Provider(ethereum);
        setProvider(provider);
    }

    if (!dedicatedProvider) {
        dedicatedProvider = new ethers.providers.JsonRpcProvider(rpc_url);
        setDedicatedProvider(dedicatedProvider);
    }

    if (!contract && provider) {
        contract = new ethers.Contract(contract_address, FantansyShoujoAbi, provider.getSigner());
        setContract(contract);
    }

    if (!contractRO && dedicatedProvider) {
        contractRO = new ethers.Contract(contract_address, FantansyShoujoAbi, dedicatedProvider);
        setContractRO(contractRO);
    }

    function switchToCorrectChain() {
        return switchChain(chain_id).then(() => window.location.reload());
    }

    let connected = false;

    switch (status) {
        case 'connected':
            connected = true;
            console.log('connected wallet:', account, 'on chain id', chainId);
            break;

        case 'unavailable':
            console.log('MetaMask is not available');
            break;

        case 'initializing':
            console.log('MetaMask is initializing');
            break;

        default:
            console.log('not connected:', status);
            break;
    }

    function changeWallet() {
        ethereum.request({method: 'wallet_requestPermissions', params: [{eth_accounts: {}}]});
    }

    async function mint() {
        console.log('mint');

        if (!contract) {
            console.log('contract not ready');
            return connect();
        }

        if (!price) {
            console.log('price not ready');
            return;
        }

        try {
            const r = await contract.mint({
                value: price,
                // value: ethers.utils.parseEther('0.02'),
                // gasPrice: ethers.utils.parseUnits('0.1', 'gwei'),
                // gasLimit: 200000,
            });
            console.log('mint result:', r);
            await r.wait();
            console.log('minted');
            window.location.reload();
        } catch (e) {
            console.log('mint error:', e);
            Swal.fire({
                title: 'Mint Error',
                text: 'You may exceed the quota or have insufficient balance.',
                icon: 'error',
            })
        }
    }

    let btn = <button className={'btn ' + styles.mintBtn} onClick={connect}>
        Connect Wallet
    </button>;

    if (chainId && chainId.toLowerCase() !== chain_id.toLowerCase()) {
        console.log('current chain id', chainId, 'is not', chain_id);
        //So, the best practice when a network change occurs is to simply refresh the page.
        btn = <button className={'btn ' + styles.mintBtn} onClick={switchToCorrectChain}>
            Switch Network
        </button>;
    } else if (connected) {
        console.log('mintable:', mintable);
        switch (mintable) {
            case 0:
                btn = <button className={'btn ' + styles.mintBtn} onClick={mint}>
                    Mint
                </button>;
                break;

            case 1:
                btn = <button className={'btn ' + styles.mintBtn} disabled>
                    Not Started
                </button>;
                break;

            case 2:
                btn = <button className={'btn ' + styles.mintBtn} disabled>
                    Sold Out
                </button>;
                break;

            case 3:
                btn = <button className={'btn ' + styles.mintBtn} disabled>
                    Not Eligible
                </button>;
                break;

            default:
                btn = <button className={'btn ' + styles.mintBtn} disabled>
                    Loading
                </button>;
                break;
        }
    }

    let minted_text = 'Loading...';
    if (mintedCount >= 0 && supply >= 0) {
        minted_text = `${mintedCount}/${supply}`;
    }

    return <>
        <div className="container" style={{minHeight: '100vh'}}>
            <div className={styles.socialLinks}>
                <a href="https://i.ls" target="_blank" rel="noreferrer">
                    Website
                </a>
                <a href="https://twitter.com/ils_nft" target="_blank" rel="noreferrer">
                    Twitter
                </a>
                <a href="https://discord.gg/ils" target="_blank" rel="noreferrer">
                    Discord
                </a>
                {connected ? <span onClick={changeWallet}>
                    {maskAddress(account!)}
                </span> : <span onClick={connect}>
                    Connect
                </span>}

            </div>
            <div className={`row ${styles.mainContent}`}>
                <div className="col-12 col-lg-6">
                    <img src="https://i.ls/assets/img/1.png"
                         style={{maxWidth: '550px', width: '100%', borderRadius: '25px'}}/>
                </div>
                <div className={`col-12 col-lg-6 ${styles.mintInfo}`}>
                    <h1 className={styles.mintInfoTitle}>Fantasy Shoujo</h1>
                    <p className={styles.mintInfoDescr}>
                        The summon of 6000 girls implies aborigines of i.ls NFT world arrives. The world and the
                        ecosystem are in their hands now.
                    </p>
                    <p className={styles.mintInfoPrice}>
                        Mint Price: {price ? ethers.utils.formatEther(price) : 'Loading'} ETH
                    </p>
                    <p className={styles.mintInfoMinted}>Minted: {minted_text}</p>

                    <div className={styles.mintInfoMintDiv}>
                        {/*<div className="mb-3">*/}
                        {/*    <input type="radio" className="btn-check" name="amount" id="x1" autoComplete="off" checked/>*/}
                        {/*    <label className="btn btn-outline-primary me-2" htmlFor="x1">x1</label>*/}

                        {/*    <input type="radio" className="btn-check" name="amount" id="x2" autoComplete="off"/>*/}
                        {/*    <label className="btn btn-outline-primary me-2" htmlFor="x2">x2</label>*/}

                        {/*    <input type="radio" className="btn-check" name="amount" id="x3" autoComplete="off"/>*/}
                        {/*    <label className="btn btn-outline-primary me-2" htmlFor="x3">x3</label>*/}
                        {/*</div>*/}
                        <p>
                            {btn}
                        </p>
                        <p>Limited to 1 per wallet</p>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

function maskAddress(address: string) {
    return address.substring(0, 6) + '...' + address.substring(address.length - 4, address.length);
}
