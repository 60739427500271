import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {MetaMaskProvider} from "metamask-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MetaMaskProvider>
        <App />
    </MetaMaskProvider>
  </React.StrictMode>
);
